import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import data from '../data/content.json';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import useTracking from './useTracking';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        overflow: "auto",
        //backgroundColor: theme.palette.background.paper,
        height: "calc(100vh - 57px)",
        "@media (min-width:0px) and (orientation: landscape)": {
            height: "calc(100vh - 49px)"
        },
        "@media (min-width:600px)": {
            height: "calc(100vh - 65px)"
        },
        backgroundColor: "#C9D6FF",
        //background: "-webkit-linear-gradient(to right, #E2E2E2, #C9D6FF)",
        background: "linear-gradient(to right, #E2E2E2, #C9D6FF)"

    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    listText: {
        wordBreak: "break-word",
        whiteSpace: "normal"
    }


}))


export default function ContentList({ handleDrawerToggle }) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    useTracking();

    const { params = {} } = useRouteMatch({
        path: "/read/:id"
    }) || {}

    const matchHome = useRouteMatch({
        path: "/",
        exact: true
    })

    // const [open, setOpen] = useState("/");

    useEffect(() => {
        if (params.id) {
            const findContent = data.find(content => content.id === params.id);
            if (!findContent) {


                history.push("/")
            }
        }
    }, [params.id, history])





    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            //     subheader={
            //         <ListSubheader component="div" id="nested-list-subheader">
            //             Nested List Items
            // </ListSubheader>
            //     }
            className={classes.root}
        >
            <ListItem button onClick={handleDrawerToggle} component={Link} to="/" selected={matchHome !== null} >
                <ListItemText primary="Home" primaryTypographyProps={{ className: classes.listText }} />
            </ListItem>

            {
                data.map(content => {

                    return <React.Fragment key={content.id}>
                        <ListItem button onClick={handleDrawerToggle} component={Link} to={`/read/${content.id}`} selected={content.id === params.id}>
                            <ListItemText primary={content.title} primaryTypographyProps={{ className: classes.listText }} />
                            {Array.isArray(content.subs) ?
                                (content.id === params.id ? <ExpandLess /> : <ExpandMore />)
                                : null}
                        </ListItem>
                        {Array.isArray(content.subs) ?
                            <Collapse in={content.id === params.id} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {content.subs.map((sub, index) => {

                                        return <ListItem onClick={handleDrawerToggle} key={sub.hash + index} button component={Link} to={`/read/${content.id}${sub.hash}`} className={classes.nested} selected={location.hash === sub.hash}>
                                            <ListItemText primary={sub.title} primaryTypographyProps={{ className: classes.listText }} />
                                        </ListItem>
                                    })}
                                </List>
                            </Collapse> : null
                        }
                    </React.Fragment>
                })
            }


        </List>
    );
}