import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LockIcon from '@material-ui/icons/Lock';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
export default function Locked() {

    return (<Grid style={{ paddingTop: 16, paddingBottom: 16 }} container item xs={12} alignItems="center" direction="column" spacing={2}>
        <Grid item xs={12}>
            <LockIcon style={{ fontSize: 100, color: 'gray' }} />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h3" align="center">Ups! Materi Ini Terkunci</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography align="center">Dapatkan Full Akses Hanya:</Typography>
            <Typography color="error" align="center"> <strike>Rp450.000</strike> -&gt; Rp157.500</Typography>
        </Grid>
        <Grid item xs={12}>
            <Button color="inherit" variant="contained" href="https://member.jagoreact.com/signup-serverless" target="_blank">

                <ShoppingCartIcon style={{ marginRight: 8 }} /> Order Sekarang

            </Button >
        </Grid>
        <Grid item xs={12} container justify="center">
            <Link align="center" component={RouterLink} to="/read/mendapatkan-full-akses">Pelajari lebih lanjut cara untuk mendapatkan full akses disini!</Link>
        </Grid>
    </Grid>)
}