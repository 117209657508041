import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#1e1e1e"
        },
        secondary: {
            main: "#ffffff"
        }
    },
    typography: {
        h1: {
            fontSize: "3.5em"
        },
        h2: {
            fontSize: "2.5em"
        },
        h3: {
            fontSize: "2em"
        },
        h4: {
            fontSize: "1.7em"
        },
        h5: {
            fontSize: "1.5em"
        },
        h6: {
            fontSize: "1em"
        }
    }
})

export default responsiveFontSizes(theme);