import React, { useCallback } from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
// @material-ui theme
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './config/theme';

import { SnackbarProvider } from "notistack";

// import { mainListItems, secondaryListItems } from './listItems';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import FirebaseProvider from './components/FirebaseProvider';
import Read from './pages/read';
import ContentList from './components/ContentList';
import LoginByToken from './pages/login';
import PromoIcon from './components/PromoIcon';
import Home from './pages/home';
import NotFound from './pages/404';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://jagoreact.com/">
        JagoReact.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 16px',
    backgroundColor: "#C9D6FF",
    //background: "-webkit-linear-gradient(to right, #E2E2E2, #C9D6FF)",
    background: "linear-gradient(to right, #E2E2E2, #C9D6FF)",
    ...theme.mixins.toolbar,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#0f0c29",  /* fallback for old browsers */
    //background: "-webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29)",  /* Chrome 10-25, Safari 5.1-6 */
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },

  divider: {
    backgroundColor: "#0f0c29"
  }
}));

export default function App() {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(open => !open);
  }, []);
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <FirebaseProvider>
            <Router>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
                    Membangun Aplikasi Full-Stack Serverless dengan AWS dan React
          </Typography>
                  <PromoIcon />
                </Toolbar>
              </AppBar>

              <Hidden smUp implementation="js">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <div className={classes.toolbarIcon}>
                    <Typography variant="h5" component="h1">JagoReact.com</Typography>

                  </div>
                  <Divider className={classes.divider} />
                  <ContentList handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="js">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  <div className={classes.toolbarIcon}>
                    <Typography variant="h5" component="h1">JagoReact.com</Typography>

                  </div>
                  <Divider className={classes.divider} />
                  <ContentList handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
              </Hidden>

              <main className={classes.content} id="content">
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                  <Grid container spacing={3}>

                    <Grid item xs={12}>
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/read/:id" component={Read} />
                        <Route exact path="/login-by-token/:token" component={LoginByToken} />
                        <Route component={NotFound} />
                      </Switch>
                    </Grid>
                  </Grid>
                  <Box pt={4}>
                    <Copyright />
                  </Box>
                </Container>
              </main>
            </Router>
          </FirebaseProvider>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}