import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import slugify from 'slugify';
import { Element } from 'react-scroll';
const flatten = (text, child) => {
    return typeof child === 'string'
        ? text + child
        : React.Children.toArray(child.props.children).reduce(flatten, text);
};

/**
 * HeadingRenderer is a custom renderer
 * It parses the heading and attaches an id to it to be used as an anchor
 */
const HeadingRenderer = props => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = slugify(text, { lower: true, strict: true });

    return <Element name={'#' + slug}><Typography variant={'h' + props.level} id={slug} gutterBottom><Link href={'#' + slug}>{props.children}</Link></Typography></Element>
};

export default HeadingRenderer;