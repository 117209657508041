import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';


export default function useTracking() {

    const location = useLocation();
    const previousPathNameRef = useRef();

    useEffect(() => {
        const previousPathName = previousPathNameRef.current
        if (previousPathName && previousPathName !== location.pathname) {

            if (window.gtag) {
                window.gtag("config", "UA-140149000-3", { 'page_path': location.pathname })
            }
        }
        previousPathNameRef.current = location.pathname
    }, [location.pathname])

    return null
}