const firebaseConfig = {
    apiKey: "AIzaSyBncyQUfN81qkOwyuIut12o_SSF2QFc4Y0",
    authDomain: "prod-jagoreact-com.firebaseapp.com",
    databaseURL: "https://prod-jagoreact-com.firebaseio.com",
    projectId: "prod-jagoreact-com",
    storageBucket: "prod-jagoreact-com.appspot.com",
    messagingSenderId: "973515628544",
    appId: "1:973515628544:web:0f3462ff504bf7d97338e5"
};

export default firebaseConfig;