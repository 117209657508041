import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { functions } from '../../components/FirebaseProvider';
import { useSnackbar } from 'notistack';
const useStyles = makeStyles(theme => ({
    iconLeft: {
        marginRight: theme.spacing(1)
    }
}))
export default function Download() {
    const classes = useStyles();
    const [linkHttpApi, setLinkHttpApi] = useState(null);
    const [linkReactApp, setLinkReactApp] = useState(null);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const buatLinkDownload = async (e) => {

        setLoading(true);

        try {
            const callReqFile = functions.httpsCallable('reqFile');

            const resultHttpApi = await callReqFile({
                produkId: 'react-aws-serverless',
                fileName: 'serverless/sources/jagoreact-job-board-api-master.zip'
            });

            setLinkHttpApi(resultHttpApi.data.signedUrl);

            const resultReactApp = await callReqFile({
                produkId: 'react-aws-serverless',
                fileName: 'serverless/sources/jagoreact-job-board-master.zip'
            });

            setLinkReactApp(resultReactApp.data.signedUrl);

        } catch (e) {

            enqueueSnackbar("Gagal membuat link downloa!", { variant: "error" })
        }
        setLoading(false);
    }
    return <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
            <Typography variant="h1" gutterBottom>Download Source Code</Typography>
        </Grid>


        <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
                <Typography>Klik tombol dibawah ini untuk membuat link download:</Typography>
                <Button variant="outlined" onClick={buatLinkDownload} disabled={loading}> Buat Link Download </Button>
            </Grid>
            <Grid item xs={12}>
                {loading && <CircularProgress />}
            </Grid>
            <Grid item xs={12}>
                {linkHttpApi && <Button variant="contained" href={linkHttpApi} target="_blank"><DownloadIcon className={classes.iconLeft} /> Donwload Source Code HTTP API</Button>}
            </Grid>
            <Grid item xs={12}>
                {linkReactApp && <Button variant="contained" href={linkReactApp} target="_blank"><DownloadIcon className={classes.iconLeft} /> Donwload Source Code React</Button>}
            </Grid>
        </Grid>


    </Grid>

}