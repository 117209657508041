import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        // paddingLeft: theme.spacing(4),
        // paddingRight: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}))
export default function NotFound() {
    const classes = useStyles();
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Typography variant="h1" paragraph>Halaman Tidak Ditemukan</Typography>
                <Typography paragraph>Mohon maaf halaman yang anda tuju tidak ditemukan!</Typography>
                <Grid container item xs={12} justify="flex-start">
                    <Button variant="outlined" component={Link} to="/read/daftar-isi">Cek Daftar Isi</Button>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
}