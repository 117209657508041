import React from 'react';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}))
export default function Cover() {
    const classes = useStyles();
    return <div className={classes.root}><img src="https://nos.jkt-1.neo.id/jagoreact/serverless/images/cover-serverless.png" alt="Membangun Aplikasi Full-Stack Serverless dengan AWS dan React" style={{ maxWidth: '100%' }} />
    </div>

}