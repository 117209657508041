// React
import React, { useState, useEffect } from 'react';
// Router
import { Redirect, useParams } from 'react-router-dom';
// Components
import AppLoading from '../../components/AppLoading';
import { useSnackbar } from 'notistack';
// Firebase
import { useFirebase, auth } from '../../components/FirebaseProvider';
// 
function LoginByToken(props) {
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { location } = props;
    // Firebase
    const { user, loading } = useFirebase();
    // State
    const [isSubmitting, setSubmitting] = useState(false);
    // UseEffect
    useEffect(() => {

        if (params.token) {

            const loginByCustomToken = async () => {
                setSubmitting(true)
                try {
                    await auth.signInWithCustomToken(params.token);
                } catch (e) {
                    enqueueSnackbar("Token tidak valid", { variant: "error" })
                }
                setSubmitting(false)
            }

            loginByCustomToken()
        }
    }, [enqueueSnackbar, params.token]);

    // Condition Loading
    if (loading || isSubmitting) {
        return <AppLoading />
    }

    // conditions when there are users for SignIn with form
    if (user) {
        const redirectTo = location.state && location.state.from ? location.state.from.pathname : '/';
        return <Redirect to={redirectTo} />
    }

    return <Redirect to="/" />
}

export default LoginByToken;
