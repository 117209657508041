import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
export default function CodeBlock({ language, value }) {

    return (<div style={{ marginBottom: 16 }}>
        <SyntaxHighlighter language={language || 'jsx'} style={dracula}>
            {value}
        </SyntaxHighlighter>
    </div>
    );
};