import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import yuda from '../../images/yuda.png'
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import IconButton from '@material-ui/core/IconButton';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//     root: {
//         paddingTop: theme.spacing(1),
//         paddingBottom: theme.spacing(1)
//     }
// }))
export default function TentangPenulis() {
    const [yearsExp] = useState(Math.floor((Date.now() - 1388941200000) / 3.154e+10));
    return <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
            <Typography variant="h1" gutterBottom>Tentang Penulis</Typography>
        </Grid>

        <Grid item xs={12} md={4} container justify="center" alignItems="center">
            <img src={yuda} alt="yuda" style={{ maxWidth: "80%" }} />
        </Grid>
        <Grid item xs={12} md={8}>
            <Typography variant="h2" paragraph>Yuda Sukmana</Typography>
            <Typography>Adalah seorang full-stack developer dengan lebih dari {yearsExp} tahun pengalaman dalam mengembangkan aplikasi web. Beberapa portofolio yang pernah dikerjakan diantaranya: sociocaster.com, melisa.id, pakbudi.id, jagoreact.com, supercoder.academy, dll. Lahir di Tasikmalaya pada tahun 1992, penulis merupakan anak pertama dari 2 bersaudara pasangan Bapak Ateng dan Ibu Udah (Alm). Sekarang, penulis tinggal di Kota Bandung bersama istri (Nike Sartika) dan memiliki seorang anak (Haura Najwa Falisha). Penulis menempuh pendidikan dasar di SDN Padasirna Jahiang Salawu dan lulus pada tahun 2004, melanjutkan pendidikan menengah pertama di MTS Negeri Salawu (MTS Negeri 7 Tasikmalaya) dan lulus pada tahun 2007, melanjutkan pendidikan menengah atas di SMK Negeri 2 Tasikmalaya jurusan Teknik Elektro (Audio dan Video) dan lulus tahun 2010, melanjukan pendidikan tinggi (S1) di Universitas Pendidikan Indonesia (UPI) jurusan Pendidikan Teknik Elektro dan lulus tahun 2015, saat ini penulis sedang melanjutkan pendidikan pascasarjana (S2) di Institut Teknologi Bandung (ITB) jurusan Teknik Elektro dengan konsentrasi Layanan Teknologi Informasi (LTI).  </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h3" paragraph>Social Media</Typography>
            <Typography>Penulis dapat dihubungi melalui social media berikut:</Typography>
            <IconButton href="mailto:yudasukmana1992@gmail.com"><EmailIcon fontSize="large" /></IconButton>
            <IconButton href="https://facebook.com/ydatech" target="_blank"><FacebookIcon fontSize="large" /></IconButton>
            <IconButton href="https://twitter.com/ydatech" target="_blank"><TwitterIcon fontSize="large" /></IconButton>
            <IconButton href="https://github.com/ydatech" target="_blank"><GitHubIcon fontSize="large" /></IconButton>
            <IconButton href="https://instagram.com/yuda_sukmana" target="_blank"><InstagramIcon fontSize="large" /></IconButton>


        </Grid>


    </Grid>

}