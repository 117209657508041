import React from 'react';
import { useFirebase } from './FirebaseProvider';

import Button from '@material-ui/core/Button';

import LoyaltyIcon from '@material-ui/icons/Loyalty';
export default function PromoIcon() {

    const { user } = useFirebase();

    return (<>{!user ?
        <Button color="inherit" href="https://member.jagoreact.com/signup-serverless" target="_blank"> <LoyaltyIcon style={{ marginRight: 8 }} /> 65% Off

        </Button > : null
    }</>)
}