import React, { useState, useLayoutEffect, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import demo from '../../images/demo.png';
import yuda from '../../images/yuda.png';
import cover from '../../images/cover-serverless-mini.png';
import arsitektur from '../../images/arsitektur-serverless.png';
import Divider from '@material-ui/core/Divider';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import qs from 'query-string';
import { Element, animateScroll, scroller } from 'react-scroll';
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
        // paddingRight: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    image: {
        borderRadius: 4,
        maxWidth: "100%",
        border: "1px solid gray",
        padding: theme.spacing(1)
    },
    section: {
        paddingTop: `${theme.spacing(4)}px !important`,
        paddingBottom: `${theme.spacing(4)}px !important`
    },
    reverseOrder1: {
        order: 1,
        [theme.breakpoints.up("sm")]: {

            order: 2
        }
    },
    reverseOrder2: {
        order: 2,
        [theme.breakpoints.up("sm")]: {

            order: 1
        }
    },
    iconLeft: {
        marginRight: theme.spacing(1)
    }
}))
export default function Home() {
    const classes = useStyles();
    const [yearsExp] = useState(Math.floor((Date.now() - 1388941200000) / 3.154e+10));
    const location = useLocation();
    const [persistRef, setPersistRef] = useState();
    useLayoutEffect(() => {

        if (location.hash) {

            scroller.scrollTo(location.hash, {
                smooth: true,
                containerId: 'content',
                offset: -70, // Scrolls to element + 50 pixels down the page
            })
        } else {
            animateScroll.scrollToTop({
                containerId: 'content'
            })
        }

    })

    useEffect(() => {
        if (location.search) {
            const query = qs.parse(location.search);
            if (query.ref) {
                setPersistRef(`https://member.jagoreact.com/persist-ref/${query.ref}`);
            }

        }
    }, [location.search])
    return <>
        <Helmet>
            <title>Membangun Aplikasi Full-Stack Serverless dengan AWS dan React</title>
        </Helmet>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>

                    <Grid container alignItems="center" justify="center" spacing={2}>

                        <Grid item xs={12} className={classes.section}>

                            <Typography variant="h4" gutterBottom align="center">Membangun Aplikasi Full-Stack Serverless dengan AWS dan React</Typography>
                            <Typography variant="subtitle2" paragraph align="center">Sebuah E-Book dalam Bahasa Indonesia yang akan menjadikan anda seorang pengembang aplikasi web modern yang handal.
                            </Typography>
                            <Typography variant="subtitle2" paragraph align="center">
                                Disajikan secara sederhana, namun tetap up-to-date dengan standar dan perkembangan terbaru baik itu di ekosistem AWS maupun React.</Typography>
                            <Grid container item xs={12} justify="center" alignItems="flex-start">
                                <Button component={Link} variant="outlined" color="primary" to="#order-sekarang">Dapatkan Akses</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                        <Grid item xs={12} container alignItems="center" spacing={2} className={classes.section}>

                            <Grid item xs={12} sm={6}>
                                <Typography variant="h4" align="center" gutterBottom>Studi Kasus</Typography>

                                <Typography align="center" paragraph>Dalam e-book ini kita akan membahas mengenai konsep dasar dan juga studi kasus aplikasi web untuk daftar lowongan kerja React di Indonesia yang akan kita sebut dengan JagoReact Job Board. Aplikasi ini akan kita bangun sepenuhnya dengan menggunakan layanan-layanan serverless.</Typography>
                                <Grid item container xs={12} justify="center">
                                    <Button variant="outlined" color="primary" href="https://jobs.jagoreact.com" target="_blank">Demo Aplikasi</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img src={demo} className={classes.image} alt="demo" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} >
                            <Divider />
                        </Grid>

                        <Grid item xs={12} container alignItems="center" spacing={2} className={classes.section}>


                            <Grid item xs={12} sm={6} className={classes.reverseOrder1}>
                                <Typography variant="h4" align="center" gutterBottom>Yang Akan Dipelajari</Typography>

                                <Typography align="center" paragraph>Kita akan mempelajari bagaimana membangun backend berupa HTTP API dengan layanan-layanan serverless dari Amazon Web Services dan untuk sisi frontend kita akan mempelajari bagaimana membangun sebuah Single Page Application  dengan React dan Netlify. </Typography>

                                <Grid item container xs={12} justify="center">
                                    <Button variant="outlined" component={Link} color="primary" to="/read/daftar-isi">Lihat Daftar Isi</Button>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.reverseOrder2}>
                                <img src={arsitektur} className={classes.image} alt="demo" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} >
                            <Divider />
                        </Grid>

                        <Grid item xs={12} container alignItems="center" spacing={2} className={classes.section}>


                            <Grid item xs={12} sm={6}>
                                <Typography align="center" variant="h4" gutterBottom>Tentang Penulis</Typography>

                                <Typography align="center" paragraph><strong>Yuda Sukmana</strong> adalah seorang full-stack developer dengan lebih dari {yearsExp} tahun pengalaman dalam mengembangkan aplikasi web. Beberapa portofolio yang pernah dikerjakan diantaranya: sociocaster.com, melisa.id, pakbudi.id, jagoreact.com, supercoder.academy, dll. </Typography>

                                <Grid item container xs={12} justify="center">
                                    <Button variant="outlined" component={Link} color="primary" to="/read/tentang-penulis">Selengkapnya</Button>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img src={yuda} style={{ maxWidth: "60%" }} alt="author" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                        <Grid item xs={12} container alignItems="center" spacing={2} className={classes.section}>

                            <Grid item xs={12}>
                                <Element name="#order-sekarang">
                                    <Typography variant="h3" id="order-sekarang" align="center" paragraph>Order Sekarang</Typography>

                                </Element>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <img src={cover} className={classes.image} alt="cover" style={{ maxWidth: "60%" }} />
                            </Grid>
                            <Grid item xs={12} sm={6} container spacing={2}>

                                <Grid item xs={12}>   <Typography variant="h4" align="center">Dapatkan Full Akses Hanya:</Typography></Grid>
                                <Grid item xs={12}>

                                    <Typography color="error" align="center"> <strike>Rp450.000</strike> -&gt; Rp157.500</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<span>Akses Full  Materi E-Book</span>}
                                            />
                                        </ListItem >
                                        <ListItem button>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Akses Seluruh Source Code"
                                            />
                                        </ListItem>

                                        <ListItem button>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Akses Ke Private Telegram Group"
                                            />
                                        </ListItem>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Lisensi Untuk 1 Orang"
                                            />
                                        </ListItem>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Lifetime Update"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item container xs={12} justify="center">

                                    <Button variant="outlined" color="primary" href="https://member.jagoreact.com/signup-serverless" target="_blank"> <ShoppingCartIcon className={classes.iconLeft} />Order Sekarang</Button>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        {
            persistRef && <iframe title="persit ref" src={persistRef} style={{ width: 0, height: 0, border: 0 }}></iframe>
        }
    </>
}