import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import data from '../../data/content.json';

import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({

    nested: {
        paddingLeft: theme.spacing(8),
    },
    listText: {
        wordBreak: "break-word",
        whiteSpace: "normal"
    }


}))

export default function DaftarIsi() {
    const classes = useStyles();
    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"

        >

            <ListItem button component={Link} to="/">
                <ListItemIcon>
                    <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText primary="Home" primaryTypographyProps={{ className: classes.listText }} />
            </ListItem>
            {
                data.map(content => {

                    return <React.Fragment key={content.id}>
                        <ListItem button component={Link} to={`/read/${content.id}`} >
                            <ListItemIcon>
                                <ChevronRightIcon />
                            </ListItemIcon>
                            <ListItemText primary={content.title} primaryTypographyProps={{ className: classes.listText }} />
                        </ListItem>
                        {Array.isArray(content.subs) ?

                            <List component="div" disablePadding>
                                {content.subs.map((sub, index) => {
                                    return <ListItem key={sub.hash + index} button component={Link} to={`/read/${content.id}${sub.hash}`} className={classes.nested} >
                                        <ListItemIcon>
                                            <ChevronRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={sub.title} primaryTypographyProps={{ className: classes.listText }} />
                                    </ListItem>
                                })}
                            </List> : null
                        }
                    </React.Fragment>
                })
            }


        </List>
    );
}