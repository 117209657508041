
import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';

import { scroller, animateScroll } from 'react-scroll';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ReactMakrdown from 'react-markdown';
import CodeBlock from '../../components/CodeBlock';
import Heading from '../../components/Heading';
import { useLocation, useParams } from 'react-router-dom';
import Paragraph from '../../components/Pargraph';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ContentSkeleton from '../../components/ContentSkeleton';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import data from '../../data/content.json';
import { Helmet } from 'react-helmet';
import Hidden from '@material-ui/core/Hidden';
import { useFirebase, functions } from '../../components/FirebaseProvider';
import Locked from './locked';
import DaftarIsi from './dafar-isi';
import Cover from './cover';
import TentangPenulis from './tentang-penulis';
import Download from './download';

const pageComponents = {
    "DaftarIsi": DaftarIsi,
    "Cover": Cover,
    "TentangPenulis": TentangPenulis,
    "Download": Download
}

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    leftIcon: {
        marginRight: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
}))

export default function Read() {
    const classes = useStyles();
    const [md, setMd] = useState('');
    const location = useLocation();
    const params = useParams();
    const [content, setContent] = useState({});
    const { user } = useFirebase();
    const uid = user ? user.uid : null;
    const [loading, setLoading] = useState(false);
    const [locked, setLocked] = useState(false);
    const [comp, setComp] = useState(null);
    useLayoutEffect(() => {

        if (location.hash) {

            scroller.scrollTo(location.hash, {
                smooth: true,
                containerId: 'content',
                offset: -70, // Scrolls to element + 50 pixels down the page
            })
        } else {
            animateScroll.scrollToTop({
                containerId: 'content'
            })
        }

    })

    useEffect(() => {
        if (params.id) {
            const findContent = data.find(content => content.id === params.id);
            if (findContent) {
                setContent(findContent);
                async function fetchMd() {
                    setLoading(true);
                    try {
                        if (process.env.NODE_ENV === 'development') {

                            if (findContent.file) {
                                const response = await fetch(`http://localhost:8080/${findContent.file}`)
                                const text = await response.text();
                                setMd(text);
                            } else if (findContent.component) {

                                setComp(findContent.component)

                            }

                        } else {
                            const isPublic = findContent.type === 'public';

                            if (isPublic) {
                                if (findContent.file) {
                                    const response = await fetch(`https://nos.jkt-1.neo.id/jagoreact/serverless/${findContent.file}`);
                                    const text = await response.text();
                                    setMd(text);
                                } else if (findContent.component) {

                                    setComp(findContent.component)

                                }
                                setLocked(false);
                            } else {
                                if (uid) {

                                    try {
                                        if (findContent.file) {
                                            const callReqFile = functions.httpsCallable('reqFile');

                                            const result = await callReqFile({
                                                produkId: 'react-aws-serverless',
                                                fileName: `serverless/${findContent.file}`
                                            });

                                            const response = await fetch(result.data.signedUrl);
                                            const text = await response.text();
                                            setMd(text);
                                        } else if (findContent.component) {

                                            setComp(findContent.component)

                                        }
                                        setLocked(false);

                                    } catch (e) {
                                        setLocked(true);
                                    }

                                } else {
                                    setLocked(true);
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e.message)
                    }
                    setLoading(false)
                }

                fetchMd();
            }
        }
    }, [params.id, uid])

    const markdown = useMemo(() => {
        return <ReactMakrdown
            source={md}
            renderers={{
                code: CodeBlock,
                paragraph: Paragraph,
                heading: Heading,
                table: props => <TableContainer component={Box} marginBottom={2}><Table>{props.children}</Table></TableContainer>,
                tableHead: TableHead,
                tableBody: TableBody,
                tableRow: TableRow,
                tableCell: props => <TableCell >{props.children}</TableCell>,
                link: props => {

                    return <Link {...props} color="initial" style={{ wordBreak: "break-word" }} />
                }
            }}
            escapeHtml={false}
            transformImageUri={(uri) => {
                const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : 'https://nos.jkt-1.neo.id/jagoreact/serverless/';
                return baseUrl + uri;
            }}
            linkTarget="_blank"


        />
    }, [md]);

    const component = useMemo(() => {
        if (!comp)
            return null

        const Comp = pageComponents[comp];
        return <Comp />

    }, [comp]);

    return <>
        <Helmet>
            <title>{content.title}</title>
        </Helmet>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {loading ? <Skeleton >
                    <Breadcrumbs>

                        <Link color="inherit" component={RouterLink} to="/" >
                            Home
      </Link>
                        <Typography color="textPrimary">Breadcrumb</Typography>

                    </Breadcrumbs>
                </Skeleton> :
                    <Breadcrumbs>

                        <Link color="inherit" component={RouterLink} to="/" >
                            Home
 </Link>
                        <Typography color="textPrimary">{content.title}</Typography>

                    </Breadcrumbs>
                }
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>{
                    loading ?
                        <ContentSkeleton /> :
                        (
                            locked ? <Locked /> : (
                                content.file
                                    ?
                                    markdown
                                    :
                                    component
                            )
                        )

                }</Paper>
            </Grid>
            <Grid item xs>
                {loading ?
                    <Skeleton variant="rect" height={20} width={150} /> :
                    <Button disabled={!content.previous} component={RouterLink} to={`/read/${content.previous}`}> <ChevronLeftIcon className={classes.leftIcon} /> <Hidden xsDown implementation="js">Materi </Hidden> Sebelumnya</Button>
                }
            </Grid>
            <Grid item >

                {loading ?
                    <Skeleton variant="rect" height={20} width={150} /> :
                    <Button disabled={!content.next} component={RouterLink} to={`/read/${content.next}`}> <Hidden xsDown implementation="js">Materi </Hidden> Selanjutnya <ChevronRightIcon className={classes.rightIcon} /> </Button>
                }
            </Grid>
        </Grid>
    </>
}