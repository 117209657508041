import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';


export default function ContentSkeleton() {
    return <>
        <Skeleton><Typography variant="h1">Title Skeleton Sebagai Contoh</Typography></Skeleton>
        <Skeleton variant="rect" height={118} />
        <Skeleton><Typography variant="h2">Title Skeleton Sebagai Contoh</Typography></Skeleton>
        <Skeleton variant="rect" height={400} />
        <Skeleton><Typography variant="h3">Title Skeleton Sebagai Contoh</Typography></Skeleton>
    </>

}

